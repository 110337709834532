<script lang="ts" setup>
import { useDefaultSettingsStore } from "~/stores/defaultSettingsStore"
import MenuSubHeader from "./MenuSubHeader.vue"
import { SUPPORTED_PAGES_ENUM } from "~/types/breadcrumb"
const $q = useQuasar()
const i18n = useI18n()

/**
 *
 *  Props and emits section
 *
 *
 */

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */
const defaultSettingsStore = useDefaultSettingsStore()
const { settings } = storeToRefs(defaultSettingsStore)

const information = computed(() => {
    return settings.value?.information
})

const menuItems = computed(() => [
    {
        route: "/store",
        name: i18n.t("home.store"),
        page: SUPPORTED_PAGES_ENUM.Store,
    },
    // {
    //     route: "/store?onlyCombo=true",
    //     name: i18n.t("home.combos"),
    //     page: SUPPORTED_PAGES_ENUM.Combos,
    // },
    {
        route: "/info",
        name: i18n.t("home.about_us"),
        page: SUPPORTED_PAGES_ENUM.AboutUs,
        isHidden: !information.value?.enable_about_us,
    },
    {
        route: "/blog",
        name: i18n.t("home.blog"),
        page: SUPPORTED_PAGES_ENUM.Blog,
    },
    {
        route: "/information/frequent-asks",
        name: i18n.t("home.faq"),
        page: SUPPORTED_PAGES_ENUM.FAQ,
        isHidden: !information.value?.enable_frequent_asks,
    },
    {
        route: "/contact-us",
        name: i18n.t("home.contact_us"),
        page: SUPPORTED_PAGES_ENUM.ContactUs,
    },
])

const contacts = computed(() => settings.value?.contacts)
const label_schedule_footer = computed(() => {
    return settings.value?.contacts?.schedule
})

const scheduleLabels = computed(() => {
    const dayWeek = [
        "",
        "Lunes",
        "Martes",
        "Miercoles",
        "Jueves",
        "Viernes",
        "Sábado",
        "Domingo",
    ]
    const labels = ref<string[]>([])
    if (
        label_schedule_footer &&
        label_schedule_footer.value &&
        label_schedule_footer.value.length > 0
    ) {
        label_schedule_footer.value.forEach(
            (schedule: { day_week: any[]; hours: string | any[] }) => {
                let label = ""

                schedule.day_week.forEach((week) => {
                    if (Array.isArray(week) && week.length === 2) {
                        label +=
                            (label ? ", " : "") +
                            dayWeek[week[0]] +
                            " a " +
                            dayWeek[week[1]]
                    } else {
                        label += (label ? ", " : "") + dayWeek[week]
                    }
                })

                if (schedule.hours.length === 1) {
                    label +=
                        ": " +
                        schedule.hours[0].timeInit +
                        " - " +
                        schedule.hours[0].timeEnd
                } else if (schedule.hours.length === 2) {
                    label +=
                        ": " +
                        schedule.hours[0].timeInit +
                        " - " +
                        schedule.hours[0].timeEnd +
                        " y " +
                        schedule.hours[1].timeInit +
                        " - " +
                        schedule.hours[1].timeEnd
                }

                labels.value.push(label)
            }
        )
    } else {
        labels.value = []
    }

    return labels.value
})

const hasAnyInformation = computed(() => {
    return (
        information?.value?.enable_term_conditions ||
        information?.value?.enable_warranty_policy ||
        information?.value?.enable_shipment_policy ||
        information?.value?.enable_return_policy ||
        information?.value?.enable_frequent_asks
    )
})

/**
 *
 *  Life cicle events
 *
 *
 */

/**
 *
 *  Functions section
 *
 *
 */

function openNetwork(url: string) {
    window.open(url)
}
</script>
<template>
    <div>
        <div class="footer tw-py-8 max-container">
            <div
                class="tw-mx-auto tw-grid tw-grid-cols-12 tw-gap-5 tw-justify-center lg:tw-justify-start"
            >
                <div
                    class="tw-w-full tw-mb-8 tw-col-span-12 lg:tw-col-span-3 tw-flex tw-flex-col tw-gap-4 lg:tw-justify-start"
                >
                    <NuxtLink to="/" class="tw-mx-auto lg:tw-mx-0">
                        <ApplicationLogo
                            src="/img/dragonmarket-footer-logo.png"
                        />
                    </NuxtLink>

                    <div
                        v-if="scheduleLabels.length > 0"
                        class="tw-pr-1 tw-items-center"
                    >
                        <strong
                            class="tw-text-base tw-text-white tw-block tw-mb-2 tw-text-center lg:tw-text-left"
                            >{{ $t("pre_footer.schedule") }}</strong
                        >

                        <div
                            class="tw-flex tw-flex-col tw-items-center lg:tw-items-start tw-break-words tw-flex-wrap"
                        >
                            <span
                                v-for="(day, i) in scheduleLabels"
                                :key="i"
                                class="tw-break-all"
                                >{{ day }}</span
                            >
                        </div>
                    </div>
                </div>

                <div
                    class="footer-list tw-col-span-12 lg:tw-col-span-3 tw-flex tw-items-between tw-h-full"
                >
                    <label>{{ $t("home.contact_us") }}</label>
                    <ul
                        class="tw-p-0 tw-list-none tw-flex tw-flex-col tw-items-center lg:tw-items-start lg:justify-between tw-h-full tw-gap-5 tw-text-secondary-900"
                    >
                        <li>
                            <q-icon
                                name="fa-solid fa-location-dot"
                                color="white"
                            />
                            <span
                                >{{ $t("fields.address") }}:
                                <span
                                    @click="
                                        openNetwork(
                                            'http://www.google.com/maps/place/' +
                                                contacts?.lat +
                                                ',' +
                                                contacts?.long
                                        )
                                    "
                                >
                                    {{ contacts?.address }}
                                </span>
                            </span>
                        </li>
                        <li>
                            <q-icon name="fa-solid fa-phone" color="white" />
                            <span>{{ $t("fields.phones") }}: </span>

                            {{ contacts?.phones.join(", ") }}
                        </li>
                        <li>
                            <q-icon name="fa-solid fa-envelope" color="white" />
                            <span
                                >{{ $t("fields.email") }}:
                                {{ contacts?.email }}</span
                            >
                        </li>
                    </ul>
                </div>

                <div
                    v-if="hasAnyInformation"
                    class="footer-list tw-w-full tw-col-span-12 lg:tw-col-span-3"
                >
                    <label>{{ $t("settings.information") }}</label>
                    <ul
                        class="tw-p-0 tw-list-none tw-flex tw-flex-col tw-items-center lg:tw-items-start lg:justify-between tw-h-full tw-gap-5"
                    >
                        <li v-if="information?.enable_term_conditions">
                            <NuxtLink to="/terms-conditions">
                                <span
                                    class="tw-pr-1 tw-text-secondary-900 hover:tw-text-white tw-text-base"
                                    >{{ $t("settings.terms_conditions") }}</span
                                >
                            </NuxtLink>
                        </li>
                        <li v-if="information?.enable_warranty_policy">
                            <NuxtLink to="/warranty-policy">
                                <span
                                    class="tw-pr-1 tw-text-secondary-900 hover:tw-text-white tw-text-base"
                                    >{{ $t("settings.warranty_policy") }}</span
                                >
                            </NuxtLink>
                        </li>
                        <li v-if="information?.enable_shipment_policy">
                            <NuxtLink to="/shipment-policy">
                                <span
                                    class="tw-pr-1 tw-text-secondary-900 hover:tw-text-white tw-text-base"
                                    >{{ $t("settings.shipment_policy") }}</span
                                >
                            </NuxtLink>
                        </li>
                        <li v-if="information?.enable_return_policy">
                            <NuxtLink to="/return-policy">
                                <span
                                    class="tw-pr-1 tw-text-secondary-900 hover:tw-text-white tw-text-base"
                                    >{{ $t("settings.return_policy") }}</span
                                >
                            </NuxtLink>
                        </li>
                    </ul>
                </div>

                <div
                    class="footer-list tw-w-full tw-col-span-12 lg:tw-col-span-3 footer-menu-items"
                >
                    <label class="tw-mb-2">{{ $t("settings.links") }}</label>
                    <div class="tw-mx-auto md:tw-mx-0">
                        <MenuSubHeader
                            variant="secondary"
                            :menuItems="menuItems"
                            class="tw-mx-2 tw-flex-col !tw-items-center lg:!tw-items-start !tw-flex-none"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="tw-w-full tw-h-[1px] tw-bg-primary-200"></div>

        <div class="footer tw-py-6 max-container">
            <div
                class="tw-flex tw-justify-between tw-flex-col-reverse tw-gap-7 sm:tw-flex-row tw-items-center tw-mx-auto"
            >
                <h6
                    class="tw-text-white tw-text-xs tw-font-medium tw-leading-none"
                >
                    {{ $t("landing.copyright") }}
                </h6>

                <ul
                    class="tw-list-none tw-p-0 tw-flex tw-flex-wrap tw-justify-center tw-gap-2"
                >
                    <li>
                        <img
                            src="../public/img/logo-tropipay-header-2.png"
                            alt=""
                            class="images_log"
                        />
                    </li>
                    <li>
                        <img
                            src="../public/img/Visa-logos1.png"
                            alt=""
                            class="images_log"
                        />
                    </li>
                    <li>
                        <img
                            src="../public/img/masterCard.png"
                            alt=""
                            class="images_log"
                        />
                    </li>
                    <li>
                        <img
                            src="../public/img/union-pay.png"
                            alt=""
                            class="images_log"
                        />
                    </li>
                    <li>
                        <img
                            src="../public/img/discover.png"
                            alt=""
                            class="images_log"
                        />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<style>
.footer {
    @apply tw-bg-[#060726] tw-text-white;
}

.footer-list {
    @apply tw-p-2 tw-text-center lg:tw-text-left tw-flex tw-flex-col tw-gap-4 tw-mx-auto lg:tw-mx-0;
}

.footer-list label {
    @apply tw-text-xl tw-font-bold;
}
.footer-list label:not(:first-child) {
    @apply tw-capitalize;
}
.footer-list li {
    @apply tw-text-base tw-cursor-pointer hover:tw-text-white tw-flex tw-gap-2 tw-items-center;
}

.footer-list li:hover a {
    @apply tw-text-secondary-200 tw-underline;
}

.images_log {
    @apply tw-w-10 tw-h-5;
}

.footer-menu-items a {
    text-align: start !important;
    line-height: 0px !important;
    font-weight: 500;
}

.footer-menu-items .tw-flex.tw-flex-1.tw-w-full.tw-items-center.tw-gap-5 {
    flex-direction: column !important;
    align-items: start;
    gap: 2.2rem !important;
    justify-content: start;
}

@media (max-width: 425px) {
    footer .footer .footer-menu-items a {
        display: block !important;
        text-align: center !important;
        width: 100%;
    }
}
</style>
