import type { Category } from "~/models/Category"
import { useSearchStore } from "~/stores/searchStore"

export const useCategoriesStore = defineStore("categoriesStore", {
    state: (): {
        categories: Category[]
        loading: boolean
        currentCategorySelected: string | null
    } => ({
        categories: [],
        currentCategorySelected: null,
        loading: false,
    }),
    actions: {
        setCategories(categories: Category[]) {
            this.categories = categories
        },
        async fetchCategories() {
            const { get } = useOFetchCustom("departments")
            this.loading = true
            await get(undefined, {
                onSuccess: ({ data }) => {
                    this.setCategories(data)
                },
                onFinish: () => {
                    this.loading = false
                },
            })
        },
        getCategories() {
            if (this.categories.length === 0) {
                this.fetchCategories()
            }
        },
        setCurrentCategorySelected(category: string | null = null) {
            const searchStore = useSearchStore()
            searchStore.setCategory(category || undefined)
            this.currentCategorySelected = category
        },
        findCategoryByName(name: string) {
            return (
                this.categories.find((category) => category.label === name) ||
                null
            )
        },
        checkIfCategoryExist(name: string) {
            return this.categories.some((category) => category.label === name)
        },
    },
})
