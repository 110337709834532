import { can as canHelper} from "../common/helpers"
import type { MenuItem } from "../models/MenuItem"
import { main_menu, navbar_menu } from "../common/main_menu"
import { clone, cloneDeep } from "lodash"

export function useFilteredMenu() {
    const menu = cloneDeep(main_menu)
    const can = useCan()

    function canShow(item: MenuItem): boolean {
        if (hasChildren(item)) {
            item.children = item.children?.filter((i) => canShow(i))

            if (item.children?.length == 0) {
                return false
            }
        }
        if(item?.can) {  
            return can((Array.isArray(item?.can) ? item.can : [item.can]) as any)
        }else{
            return canHelper()
        }
        
    }

    function hasChildren(item: MenuItem): boolean {
        if (item.children && item.children.length > 0) {
            return true
        } else {
            return false
        }
    }

    return menu.filter((i) => canShow(i))
}

export function useMenu(menu: MenuItem[]) {
    function hasChildren(item: MenuItem): boolean {
        if (item.children && item.children?.length > 0) {
            return true
        } else {
            return false
        }
    }

    function canShow(item: MenuItem): boolean {
        if (hasChildren(item)) {
            for (const child of item.children!) {
                if (canShow(child)) {
                    return true
                }
            }

            return false
        }

        return can()
    }

    return menu.filter((i) => {
        return canShow(i) == true
    })
}

export function useLeftMenu() {
    return useMenu(main_menu)
}

export function useNavbarMenu() {
    return navbar_menu.filter((i) => {
        //return i.show_condition != null ? i.show_condition!(props) : true
        return true
    })
}
