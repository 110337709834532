<script setup lang="ts">
import type { SUPPORTED_PAGES } from "~/types/breadcrumb"

/**
 *
 *  Props and emits section
 *
 *
 */

const props = defineProps<{
    menuItems: {
        route: string
        name: string
        page: SUPPORTED_PAGES
        isHidden?: boolean
    }[]
    primaryButton?: boolean
    variant?: "primary" | "secondary"
}>()

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const route = useRoute()
const router = useRouter()
const { $bus } = useNuxtApp()

/**
 *
 *  Life cicle events
 *
 *
 */

/**
 *
 *  Functions section
 *
 *
 */

const getVariantStyles = () => {
    if (props.variant === "secondary") {
        return "!tw-text-secondary-900 hover:!tw-text-white"
    }

    if (!props.primaryButton) {
        return "tw-text-secondary-900 hover:tw-text-primary-800 tw-transition-all tw-duration-500"
    }
    return "tw-text-secondary-900 tw-transition-all tw-duration-500"
}

function goTo(toRoute: string) {
    if (toRoute == "/store" && route.name == "store") {
        $bus.$emit("refresh:store-view")
    } else {
        router.push(toRoute)
    }
}
</script>

<template>
    <div
        class="tw-flex tw-flex-1 tw-w-full tw-items-center tw-gap-5 xl:tw-gap-10"
    >
        <template v-for="(menuItem, i) in menuItems">
            <NuxtLink
                :key="i"
                v-if="!menuItem.isHidden"
                @click="goTo(menuItem.route)"
                :class="`${primaryButton && i === 0 ? 'tw-bg-secondary hover:tw-bg-secondary-900 hover:tw-text-white tw-rounded-full tw-px-7 tw-py-2' : '!tw-p-0 hover:tw-text-primary-800'} tw-flex tw-items-center tw-text-sm lg:tw-text-base tw-w-max tw-font-bold tw-cursor-pointer ${getVariantStyles()}`"
            >
                {{ menuItem.name }}</NuxtLink
            >
        </template>
    </div>
</template>

<style scoped></style>
