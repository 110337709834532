<script lang="ts" setup>
import type { Category } from "~/models/Category"
//import Brands from './Brands.vue';
import { useQuasar } from "quasar"
import { computed, ref } from "vue"
import { capitalizeFirstLetter } from "~/common/helpers"
import { useCategoriesStore } from "~/stores/categoriesStore"
import { SUPPORTED_PAGES_ENUM } from "~/types/breadcrumb"
import { useDefaultSettingsStore } from "~/stores/defaultSettingsStore"

/**
 *
 *  Props and emits section
 *
 *
 */

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const departmentsStore = useCategoriesStore()
const { categories: departments } = storeToRefs(departmentsStore)
const defaultSettingsStore = useDefaultSettingsStore()
const { settings } = storeToRefs(defaultSettingsStore)

const information = computed(() => {
    return defaultSettingsStore.settings?.information
})

const $q = useQuasar()
const i18n = useI18n()
const router = useRouter()
$q.screen.setSizes({ sm: 640, md: 768, lg: 1024, xl: 1280 })
useLazyAsyncData<void>(
    "departments",
    () => departmentsStore.fetchCategories(),
    {
        server: false,
    }
)
const loadingCategories = computed(() => {
    return departmentsStore.loading
})
const allDepartments = computed(() => {
    let array: any = []
    departments.value?.forEach((department) => {
        array = array.concat(SplitArrayCategory(department))
    })

    const position = array.shift()

    array.sort((a: { label: string }, b: { label: any }) =>
        a.label?.localeCompare(b.label)
    )
    array.unshift(position)

    return array
})

const categories = computed(() => {
    return $q.screen.lt.lg
        ? (departments.value ?? [])
        : (allDepartments.value ?? [])
})

const openDropdown = ref(false)

const menuItems = computed(() => [
    {
        route: "/store",
        name: i18n.t("home.store"),
        page: SUPPORTED_PAGES_ENUM.Store,
    },
    // {
    //     route: "/store?onlyCombo=true",
    //     name: i18n.t("home.combos"),
    //     page: SUPPORTED_PAGES_ENUM.Combos,
    // },
    {
        route: "/info",
        name: i18n.t("home.about_us"),
        page: SUPPORTED_PAGES_ENUM.AboutUs,
        isHidden: !information.value?.enable_about_us,
    },
    {
        route: "/blog",
        name: i18n.t("home.blog"),
        page: SUPPORTED_PAGES_ENUM.Blog,
    },
    {
        route: "/information/frequent-asks",
        name: i18n.t("home.faq"),
        page: SUPPORTED_PAGES_ENUM.FAQ,
        isHidden: !information.value?.enable_frequent_asks,
    },
    {
        route: "/contact-us",
        name: i18n.t("home.contact_us"),
        page: SUPPORTED_PAGES_ENUM.ContactUs,
    },
])

/**
 *
 *  Life cicle events
 *
 *
 */

/**
 *
 *  Functions section
 *
 *
 */

function SplitArrayCategory(category: Category) {
    let list = [category]
    if (category.children && category.children.length > 0) {
        for (const children of category.children) {
            list = list.concat(SplitArrayCategory(children))
        }
    }

    return list
}

function goToStore(category?: Category) {
    if (category) {
        router.push({ path: `/store`, query: { category: category.id } })
    } else {
        router.push({ path: "/store" })
    }
}

function findSelectedCategory(categories: Category[]): Category | null {
    for (const category of categories) {
        if (category.selected) {
            return category
        }
        if (category.children) {
            const child = findSelectedCategory(category.children)
            if (child) {
                return child
            }
        }
    }

    return null
}

const toggleOpenDropdown = () => {
    if (loadingCategories.value) return
    openDropdown.value = !openDropdown.value
}

const goToOffers = () => {
    router.push({ path: "/offers" })
}
</script>
<template>
    <div class="tw-w-full tw-bg-white">
        <div
            class="tw-w-full tw-h-16 tw-max-h-16 tw-grid tw-grid-cols-12 tw-items-center tw-gap-5 max-container"
        >
            <div
                class="tw-flex tw-w-full tw-items-center tw-gap-5 tw-col-span-4 md:tw-col-span-8"
            >
                <q-btn-dropdown
                    class="tw-text-white tw-font-poppins tw-hidden sm:tw-block tw-w-full tw-max-w-[385px] tw-capitalize tw-px-5 tw-rounded-full tw-h-10 tw-text-base tw-font-bold tw-z-50 !tw-border-0 tw-bg-primary-800 category-menu"
                    flat
                    @click="toggleOpenDropdown"
                    dropdown-icon="none"
                >
                    <template #label>
                        <div
                            class="tw-flex tw-justify-between tw-items-center tw-w-full"
                        >
                            <q-item-label class="tw-px-auto">{{
                                $t("fields.categories")
                            }}</q-item-label>
                            <div class="tw-flex tw-items-center tw-gap-1">
                                <div
                                    v-if="loadingCategories"
                                    class="tw-cursor-pointer tw-z-40 all-pointer-events"
                                >
                                    <q-spinner-ios
                                        class="tw-text-white"
                                        size="20px"
                                    />
                                    <q-tooltip
                                        class="text-white bg-primary shadow-4"
                                        anchor="top middle"
                                        self="bottom middle"
                                        :offset="[10, 10]"
                                        >Cargando
                                        {{ $t("fields.categories") }}</q-tooltip
                                    >
                                </div>
                                <template v-else>
                                    <q-icon
                                        v-if="!openDropdown"
                                        name="menu"
                                        size="20px"
                                    />
                                    <q-icon v-else name="close" size="20px" />
                                </template>
                            </div>
                        </div>
                    </template>
                    <q-list class="tw-z-50">
                        <q-item
                            class="!tw-shadow-none !tw-bg-transparent"
                            clickable
                            v-close-popup
                            v-for="(d, i) in categories"
                            :key="i"
                            @click="goToStore(d)"
                        >
                            <q-item-section>
                                <q-item-label
                                    class="!tw-text-sm lg:!tw-text-base"
                                    >{{
                                        capitalizeFirstLetter(d.label)
                                    }}</q-item-label
                                >
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-btn-dropdown>
                <MenuSubHeader :menuItems="menuItems" primaryButton />
            </div>
            <div
                class="tw-flex tw-items-center tw-justify-end tw-col-span-8 md:tw-col-span-4 tw-font-poppins"
            >
                <button
                    @click="goToOffers()"
                    class="tw-z-40 tw-cursor-pointer tw-items-center tw-text-secondary-900 hover:tw-text-white tw-self-center tw-flex tw-bg-secondary hover:tw-bg-secondary-900 tw-h-10 tw-rounded-full tw-gap-1 tw-pl-4 tw-pr-10 tw-justify-around tw-flex-wrap offer-button"
                >
                    <img
                        src="/public/img/offer.svg"
                        alt="offer"
                        class="offer-button-icon"
                    />
                    <span class="tw-font-bold tw-text-base tw-mr-3">{{
                        $t("home.products_in_offer")
                    }}</span>
                    <q-icon
                        name="fa-solid fa-arrow-right"
                        size="16px"
                        class="offer-button-arrow"
                    ></q-icon>
                </button>
            </div>
        </div>
    </div>
</template>
<style>
.offer-button:hover .offer-button-arrow {
    transform: translateX(100%);
    transition: all 0.3s;
}

.offer-button:hover .offer-button-icon {
    filter: brightness(10);
}
.category-menu .q-menu {
    @apply !tw-shadow-none tw-border tw-border-customGray-100 !tw-top-[180px];
}

.tw-dropdown:hover .tw-dropdown-menu,
.tw-dropdown-menu:hover {
    display: block;
    z-index: 9;
}

.tw-dropdown:hover svg {
    transform: rotateX(180deg);
}

.q-btn-dropdown__arrow {
    display: none;
}
</style>
