<script lang="ts" setup>
import { useShopCartStore } from "~/stores/shopCartStore"
import ShopCartIcon from "~/icons/ShopCartIcon.vue"
import Loader from "~/components/Loader.vue"

/**
 *
 *  Props and emits section
 *
 *
 */

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const notify = useNotification()
const router = useRouter()
const shopCartStore = useShopCartStore()

/**
 *
 *  Life cicle events
 *
 *
 */

/**
 *
 *  Functions section
 *
 *
 */

function goToCart() {
    if (shopCartStore.loadingShopCart) {
        notify(
            "warning",
            "Cargando datos de su carrito, por favor espere un momento"
        )
    } else if (!shopCartStore.cartCount || shopCartStore.cartCount === 0) {
        notify("negative", "Carrito vacío")
    } else {
        router.push({ path: "/cart" })
    }
}
</script>
<template>
    <div
        class="tw-w-max tw-flex tw-flex-col tw-justify-center tw-gap-2 font-right-navbar tw-cursor-pointer"
        @click="goToCart"
    >
        <div class="tw-self-center tw-relative">
            <ShopCartIcon
                size="25"
                color="#D8D8DD"
                :class="`${shopCartStore.shopCart.count > 0 && !shopCartStore.loadingShopCart && 'shake'} tw-text-secondary-700 hover:tw-text-primary-800 hover:tw-transition-all hover:tw-duration-700`"
            />
            <q-badge
                rounded
                class="tw-text-white tw-bg-primary-800 tw-absolute -tw-top-2 -tw-right-2"
                floating
                v-show="
                    shopCartStore.shopCart.count > 0 &&
                    !shopCartStore.loadingShopCart
                "
            >
                {{ shopCartStore.shopCart.count }}
            </q-badge>
        </div>
        <span
            class="tw-text-secondary-700 tw-text-base tw-self-center"
            v-if="!shopCartStore.loadingShopCart"
        >
            {{
                shopCartStore.shopCart.count > 0
                    ? shopCartStore.shopCart.subtotal_formatted
                    : "$ 00.00"
            }}
        </span>
        <Loader v-else color="primary" size="15px" class="tw-mx-auto" />
    </div>
</template>
