export enum SUPPORTED_PAGES_ENUM {
    Home = "Home",
    Store = "Store",
    Combos = "Combos",
    ShowProduct = "ShowProduct",
    Offers = "Offers",
    ContactUs = "ContactUs",
    FAQ = "FAQ",
    AboutUs = "AboutUs",
    Blog = "Blog",
    ShopCart = "ShopCart",
    ShopDetails = "ShopDetails",
    WarrantyPolicy = "WarrantyPolicy",
    ShipmentPolicy = "ShipmentPolicy",
    ReturnPolicy = "ReturnPolicy",
    TermsConditions = "TermsConditions",
}

export interface HomeBreadCrumbItem {
    name: string
    route?: string
    isCurrent?: boolean
}

export type SUPPORTED_PAGES = keyof typeof SUPPORTED_PAGES_ENUM
export interface HomeBreadCrumbs {
    name: SUPPORTED_PAGES
    breadCrumbs: HomeBreadCrumbItem[]
}
