<script lang="ts" setup>
import { ref, computed } from "vue"
import { profile_menu } from "~/common/main_menu"
import type { MenuItem } from "~/models/MenuItem"
import { useFilteredMenu, useLeftMenu } from "~/composables/LefMenu"
import { useDefaultSettingsStore } from "~/stores/defaultSettingsStore"
import type { User } from "~/models/User"
import { useCategoriesStore } from "~/stores/categoriesStore"

/**
 *
 *  Props and emits section
 *
 *
 */

const props = defineProps<{
    toggleDrawer: () => void
}>()

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const filtered_main_menu = useFilteredMenu()
const user = useSanctumUser<User>()
const can = useCan()
const i18n = useI18n()
const router = useRouter()
const route = useRoute()
const isMenuOpen = ref(false)
const { $bus } = useNuxtApp()
const expanded = ref<number[]>([])
const computedToggleDrawer = computed(() => {
    return () => props.toggleDrawer()
})

const categoriesStore = useCategoriesStore()

const defaultSettingsStore = useDefaultSettingsStore()

const information = computed(() => {
    return defaultSettingsStore.settings?.information
})

const computedLastOneReadPermission = computed(() => {
    return (
        (user.value?.all_permissions ?? []).filter((item) =>
            item.includes("read")
        ).length > 0
    )
})

const menuItems = computed(() => [
    {
        route: "/store",
        name: i18n.t("home.store"),
        page: SUPPORTED_PAGES_ENUM.Store,
    },
    {
        route: "/info",
        name: i18n.t("home.about_us"),
        page: SUPPORTED_PAGES_ENUM.AboutUs,
        isHidden: !information.value?.enable_about_us,
    },
    {
        route: "/contact-us",
        name: i18n.t("home.contact_us"),
        page: SUPPORTED_PAGES_ENUM.ContactUs,
    },
])

/**
 *
 *  Life cicle events
 *
 *
 */

/**
 *
 *  Functions section
 *
 *
 */

const handleOpenDrawer = () => {
    if (props.toggleDrawer) {
        computedToggleDrawer.value()
    } else {
        toggleMenu()
    }
}
const goToOffers = () => {
    router.push({ path: "/offers" })
}

const searchByCategory = (category: string) => {
    categoriesStore.setCurrentCategorySelected(category as string)
    router.push({
        path: "/store",
        query: { ...route.query, category: category },
    })
}

const toggleMenu = () => {
    isMenuOpen.value = !isMenuOpen.value
}

async function profile() {
    router.push("/profile/edit")
}
function logout() {
    router.push("/logout")
}

function goTo(route_name: string) {
    router.push(route_name)
}
function canShow(item: MenuItem) {
    //return can(item?.can ?? null, user.value)
    return can()
}

const closeMenu = () => {
    if (isMenuOpen.value) {
        toggleMenu()
    }
}

const lowerCase = (value: string) => `${value}`.toLowerCase()
</script>
<template>
    <div class="tw-w-full" id="nav">
        <nav
            v-if="
                (user &&
                    (lowerCase(ROLES.ADMIN).includes(
                        lowerCase(user?.role?.name)
                    ) ||
                        lowerCase(ROLES.SUPER).includes(
                            lowerCase(user?.role?.name)
                        ) ||
                        lowerCase(ROLES.ADMON).includes(
                            lowerCase(user?.role?.name)
                        ))) ||
                computedLastOneReadPermission
            "
            class="tw-py-1 tw-bg-black"
            aria-label="Navigation"
        >
            <div
                class="max-container tw-flex tw-justify-between tw-gap-2 tw-text-white tw-text-sm tw-relative admin-top-menu"
            >
                <div class="tw-flex tw-gap-2 tw-overflow-x-auto">
                    <AdminTopMenu :menu="filtered_main_menu"></AdminTopMenu>
                </div>
            </div>
        </nav>
        <slot name="pre-nav"></slot>
        <nav class="tw-w-full tw-py-4 tw-bg-white" aria-label="Navigation">
            <div
                class="max-container tw-flex tw-justify-between tw-items-center tw-relative"
            >
                <!-- Mobile Menu Button -->
                <button
                    class="lg:tw-hidden tw-text-primary hover:tw-text-secondary focus:tw-outline-none tw-cursor-pointer"
                    @click="handleOpenDrawer"
                    style="background: none"
                >
                    <q-icon name="menu" size="36px"></q-icon>
                </button>

                <NuxtLink to="/" class="lg:tw-hidden">
                    <ApplicationLogo class="lg:tw-w-[204px] lg:tw-h-[80px]" />
                </NuxtLink>

                <div
                    class="tw-flex tw-gap-4 tw-justify-evenly tw-items-center lg:tw-hidden"
                >
                    <ProfileMenu class="!tw-flex !tw-flex-col" />
                    <ShopCartButton />
                </div>

                <!-- Desktop Menu (768px and larger) -->
                <div
                    v-if="!isMenuOpen"
                    class="tw-hidden lg:tw-flex tw-h-full tw-w-full"
                >
                    <HomeMenu />
                </div>
            </div>
        </nav>

        <HomeSubHeader class="tw-hidden lg:tw-flex"></HomeSubHeader>
        <div
            class="tw-bg-white tw-py-4 tw-block lg:tw-hidden tw-relative max-container"
        >
            <HomeSearch />
        </div>
        <q-drawer
            v-model="isMenuOpen"
            :width="300"
            elevated
            class="tw-bg-white tw-text-secondary"
        >
            <div class="tw-flex tw-flex-col tw-w-full tw-gap-2 tw-py-4">
                <NuxtLink to="/">
                    <ApplicationLogo
                        class="lg:tw-w-[204px] lg:tw-h-[85px] tw-mx-auto"
                    />
                </NuxtLink>
                <q-separator class="tw-h-[2px]"></q-separator>
                <ProfileMenu class="!tw-flex !tw-flex-col !tw-mx-auto" />
                <button
                    @click="goToOffers()"
                    class="tw-cursor-pointer tw-w-full tw-font-poppins tw-items-center tw-text-white tw-self-center tw-flex tw-bg-secondary tw-h-16 tw-gap-1 tw-justify-around tw-flex-wrap"
                >
                    <img src="/public/img/offer.svg" alt="offer" />
                    <span class="tw-font-bold tw-text-xl">{{
                        $t("home.products_in_offer")
                    }}</span>
                    <q-icon name="fa-solid fa-arrow-right" size="20px"></q-icon>
                </button>
                <MenuSubHeader
                    :menuItems="menuItems"
                    class="tw-my-2 tw-flex-col"
                />
                <DropdownCard :title="$t('categories.title')" opened>
                    <template v-if="categoriesStore.loading">
                        <CategoryFilterSkeleton :lines="5" height="5" />
                    </template>
                    <CustomTreeFilter
                        v-else
                        :options="categoriesStore.categories"
                        @onSelect="searchByCategory"
                    />
                </DropdownCard>
                <slot name="mobile-left-drawer"></slot>
            </div>
        </q-drawer>
    </div>
</template>
<style scoped>
.carrito-container {
    position: relative;
    display: inline-block;
}

.carrito-cantidad {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #0674bf;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 0px 4px;
    text-align: center;
    font-size: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
