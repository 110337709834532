<script setup lang="ts">
import { useSearchStore } from "~/stores/searchStore"
import { useCategoriesStore } from "~/stores/categoriesStore"
import { capitalizeFirstLetter } from "~/common/helpers"

/**
 *
 *  Props and emits section
 *
 *
 */

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const router = useRouter()
const route = useRoute()
const queryStore = useSearchStore()
const search = ref(queryStore.query)
const categoriesStore = useCategoriesStore()
const {
    categories,
    currentCategorySelected,
    loading: loadingCategories,
} = storeToRefs(categoriesStore)

const { $bus } = useNuxtApp()

/**
 *
 *  Life cicle events
 *
 *
 */

onMounted(() => {
    if (route.query.query) {
        queryStore.setQuery(route.query.query as string)
        search.value = queryStore.query
    }
})

onBeforeUnmount(() => {
    queryStore.setQuery("")
    search.value = ""
    queryStore.setCategory(undefined)
    currentCategorySelected.value = null
})

watch(
    () => categoriesStore.categories,
    () => {
        currentCategorySelected.value =
            categoriesStore.findCategoryByName(route.query.category as string)
                ?.label || null
    },
    { immediate: true, deep: true }
)

/**
 *
 *  Functions section
 *
 *
 */

const searchInStore = () => {
    if (search.value) {
        queryStore.setQuery(search.value)
    }
    if (
        currentCategorySelected.value &&
        categoriesStore.checkIfCategoryExist(currentCategorySelected.value)
    ) {
        categoriesStore.setCurrentCategorySelected(
            categoriesStore.findCategoryByName(currentCategorySelected.value)
                ?.label || null
        )
    }
    const params = {
        ...route.query,
        query: search.value && search.value !== "" ? search.value : undefined,
        category:
            queryStore.category && !Number.isNaN(queryStore.category)
                ? queryStore.category
                : undefined,
    }

    router.push({ path: "/store", query: params })

    $bus.$emit("update:product-list", { params })
}
const cleanSearchInStore = () => {
    search.value = ""
    queryStore.setQuery(undefined)
    queryStore.setCategory(undefined)
    categoriesStore.setCurrentCategorySelected(null)
    const queries = { ...route.query }
    delete queries.query
    delete queries.category
    if (route.path !== "/store") {
        router.push({
            path: "/store",
            query: queries,
        })
    } else {
        router.replace({ query: queries })
    }
}
</script>
<template>
    <div
        class="tw-border tw-border-customGray-200 tw-rounded-full tw-overflow-hidden !tw-bg-transparent tw-mx-auto tw-w-full md:tw-w-[520px] md:tw-max-w-[520px] tw-h-10 tw-flex tw-gap-4 tw-pl-4 tw-pr-1"
    >
        <div class="tw-flex tw-flex-1 tw-w-full tw-gap-2 tw-items-center">
            <Select
                :label="$t('home.category_search')"
                borderless
                class="!tw-w-full category-search"
                v-model:modelValue="currentCategorySelected"
                @update:model-value="searchInStore"
                :option-label="'label'"
                :option-value="'value'"
                :options="
                    categories.map((c) => ({
                        label: capitalizeFirstLetter(c.label),
                        value: c.label,
                    }))
                "
                hide-dropdown-icon="expand_less"
                dropdown-icon="expand_more"
                :loading="loadingCategories"
                emit-value
            >
                <template #loading>
                    <Loader v-if="loadingCategories" size="1.25rem" />
                </template>
            </Select>
            <q-separator class="tw-h-1/2 tw-w-[2px] tw-bg-customGray-200" />
        </div>
        <div class="tw-flex tw-flex-1 tw-justify-between">
            <q-input
                rounded
                :outlined="false"
                borderless
                :label="null"
                dense
                v-model:model-value="search"
                class="tw-my-auto tw-text-sm tw-w-max md:tw-w-full tw-font-extralight tw-text-white lg:tw-text-customGray-400 search-input tw-caret-primary !tw-bg-transparent"
                @keyup.enter="searchInStore"
            >
                <template v-slot:append>
                    <q-icon
                        dense
                        name="close"
                        size="16px"
                        round
                        flat
                        @click="cleanSearchInStore"
                        class="tw-cursor-pointer tw-text-secondary hover:tw-text-accent tw-transition-all tw-duration-500 tw-ease-in-out"
                        v-if="!!search"
                    ></q-icon>
                </template>

                <template v-slot:label>
                    <div>
                        <span class="tw-text-customGray-400">{{
                            $t("home.search")
                        }}</span>
                    </div>
                </template>
            </q-input>
            <q-icon
                dense
                name="search"
                size="16px"
                round
                flat
                @click="searchInStore"
                class="tw-text-md tw-cursor-pointer tw-bg-primary-800 hover:tw-bg-primary-900 tw-transition-all tw-duration-500 tw-ease-in-out tw-text-white tw-my-auto tw-px-4 tw-py-2 tw-rounded-full"
            />
        </div>
    </div>
</template>
<style>
.category-search .q-field__inner {
    @apply !tw-h-10 !tw-w-[120px] sm:!tw-w-max;
}

.category-search .q-field__inner span {
    @apply tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap;
}

.category-search .q-field__label div {
    @apply tw-text-xs md:tw-text-base;
}
.search-input {
    @apply tw-max-w-[100px] md:tw-max-w-max;
}
.search-input .q-field__label span {
    @apply tw-text-xs md:tw-text-base;
}
</style>
