export const useSearchStore = defineStore("searchStore", {
    state: () => ({
        query: undefined as string | undefined,
        category: undefined as string | undefined,
    }),
    getters: {
        getQuery(state) {
            return state.query
        },
        getCategory(state) {
            return state.category
        },
    },
    actions: {
        setQuery(newQuery?: string) {
            if (!newQuery) {
                this.query = undefined
            } else {
                this.query = newQuery
            }
        },
        setCategory(newCategory?: string) {
            if (!newCategory) {
                this.category = undefined
            } else {
                this.category = newCategory
            }
        },
    },
})
